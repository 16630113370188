<template>
  <div data-cy-organization-member-select>
    <organization-member-autocomplete
      v-if="!display"
      v-model="selection"
      v-model:query="query"
      autofocus
      :exclude="exclude"
      :placeholder="$t('registration_request.member_select.placeholder')"
      :readonly="!allowedToAdd"
      @close="emit('close')"
      @open="emit('open')"
    >
      <template v-if="displayCreationFormInEmptySlot" #empty>
        <p
          class="m-1 flex cursor-pointer items-center justify-between rounded bg-gray-50 p-2 pl-3 text-sm italic"
          tabindex="0"
          @click="toggleDisplay(true)"
        >
          {{
            $t("registration_request.member_select.no_email_matching", {
              organization: profile?.organization.name,
            })
          }}

          <app-button
            color="dark"
            data-cy-add-new-member
            size="small"
            tag="span"
            >{{ $t("app.cta.add") }}</app-button
          >
        </p>
      </template>
    </organization-member-autocomplete>

    <app-card v-else class="p-4" elevated outlined>
      <organization-member-creation-form
        :initial-state="{ email: query }"
        @cancel="onMemberCreationCancel"
        @submit="onCreationFormSubmit($event)"
      />
    </app-card>
  </div>
</template>

<script lang="ts" setup>
import type {
  OrganizationMemberCreationRequest,
  OrganizationMemberView,
} from "~~/model/organization.model";

const properties = defineProps<{
  modelValue: OrganizationMemberView[];
  exclude?: DatabaseTable<"organization_members">["id"][];
  max?: number;
}>();

const emit = defineEmits(["open", "close"]);

const { profile } = useOrganizationMemberProfile();
const selection = useVModel(properties, "modelValue");
const query = ref("");
const displayCreationFormInEmptySlot = ref(false);
const allowedToAdd = computed(
  () => properties.max === undefined || selection.value.length < properties.max,
);

const [display, toggleDisplay] = useToggle();
function onMemberCreationCancel() {
  toggleDisplay(false);
  emit("close");
}

watchDebounced(
  query,
  (value) => {
    displayCreationFormInEmptySlot.value =
      /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value);
  },
  { debounce: 300 },
);

const { showError, showSuccess } = useToastNotification();
async function onCreationFormSubmit(form: OrganizationMemberCreationRequest) {
  try {
    const created = await $fetch(
      `/api/organizations/${profile.value?.organization_id}/members`,
      {
        method: "POST",
        body: form,
      },
    );
    selection.value.push(created);
    showSuccess(
      `${form.first_name} a bien été ajouté à ${profile.value?.organization.name} !`,
    );
  } catch {
    showError(
      `Un souci est intervenu durant l'ajout de ${form.first_name} à ${profile.value?.organization.name}`,
    );
  } finally {
    query.value = "";
    toggleDisplay(false);
    emit("close");
  }
}
</script>
